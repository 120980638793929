import { useUser } from '@auth0/nextjs-auth0'
import React from 'react'

// styled components
import {
    ModalBody,
    ModalContent,
    ModalExitButton,
    ModalExitButtonContents,
    ModalWrapper,
    SectionWrapper,
    SubscribeButton,
} from './index.style'

// global components
import { Button } from 'components/Button'
import { Text } from 'components/Text'

// hooks
import { useRouter } from 'hooks'

// constants
import { PASS_TYPE } from 'constants/index'
import { getSiteSettings } from 'hooks/settings'
import Router from 'next/router'
import { BiArrowBack } from 'react-icons/bi'
import { trackEvent } from 'utils/helper-amplitude'

interface LeaguePassProps {
    priceId: string
    passId: string
    priceValue: number | undefined
    leagueName: string
    leagueSlug: string
}

const LeaguePass: React.FC<any> = (props: LeaguePassProps) => {
    const { priceId, passId, priceValue, leagueName, leagueSlug } = props
    const { move, asPath }: any = useRouter()
    const { user } = useUser()
    const { final_pass_league_slug, final_pass_price_id } =
        getSiteSettings().temporary

    const trackSubscribe = () => {
        trackEvent(user, 'Subscribe to League Pass', { leagueName })
    }

    const handleMenuClick = (to: any) => {
        move(to)
    }

    const returnToLeague = () => {
        leagueSlug && Router.push(`/league/${leagueSlug}`)
    }

    const finalPassMode = leagueSlug === final_pass_league_slug

    return (
        <ModalWrapper show={true} onClick={returnToLeague}>
            <ModalContent show={true}>
                <ModalBody onClick={(e) => e.stopPropagation()}>
                    <SectionWrapper
                        flexDirection="column"
                        alignItems="center"
                        padding="10px 0px 0px 0px"
                        gap="15px"
                    >
                        <ModalExitButton
                            onClick={returnToLeague}
                            id="modal-exit-button"
                            href={`/league/${leagueSlug}`}
                        >
                            <ModalExitButtonContents>
                                <BiArrowBack size={20} />
                                <p>Back to League</p>
                            </ModalExitButtonContents>
                        </ModalExitButton>
                        <Text
                            fSize={2}
                            fWeight={800}
                            tAlign="center"
                            style={{ color: 'orange' }}
                        >
                            {`${leagueName} ${
                                finalPassMode ? 'Grand Final' : 'League Pass'
                            }`}
                        </Text>
                        <Text fSize={1}>{`$${priceValue ?? 5} ${
                            finalPassMode ? 'one-time purchase' : 'monthly'
                        } incl. GST`}</Text>
                    </SectionWrapper>

                    {!user && (
                        <SectionWrapper
                            justifyContent="center"
                            padding="25px 0px 0px 0px"
                        >
                            <Text fSize={1} fWeight={700} tAlign="center">
                                {
                                    'Only logged-in users can subscribe to League Passes'
                                }
                            </Text>
                        </SectionWrapper>
                    )}

                    {!finalPassMode && (
                        <>
                            {' '}
                            <SectionWrapper
                                flexDirection="column"
                                padding="35px 0px 0px 0px"
                            >
                                <Text fSize={1} padding="0px 0px 10px 0px">
                                    {`Users who sign up for the ${leagueName} league pass will have full access to:`}
                                </Text>
                                <ul>
                                    <li
                                        style={{
                                            listStyle: 'inside',
                                            fontSize: '14px',
                                        }}
                                    >{`All ${leagueName} live streams`}</li>
                                    <li
                                        style={{
                                            listStyle: 'inside',
                                            fontSize: '14px',
                                        }}
                                    >{`All ${leagueName} games on demand`}</li>
                                    <li
                                        style={{
                                            listStyle: 'inside',
                                            fontSize: '14px',
                                        }}
                                    >{`Your payment contributes to ongoing streaming costs`}</li>
                                </ul>
                            </SectionWrapper>
                            <SectionWrapper
                                flexDirection="column"
                                padding="35px 0px 0px 0px"
                            >
                                <Text fSize={1}>
                                    {`Users can sign up via each ${leagueName} Club’s page.`}
                                </Text>
                            </SectionWrapper>
                        </>
                    )}

                    <SectionWrapper
                        flexDirection="column"
                        padding="10% 0px 15px 0px"
                        gap="15px"
                    >
                        {!user && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '49%',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    width="100%"
                                    style={{
                                        border: `1px solid red`,
                                        borderRadius: '5px',
                                    }}
                                    onClick={() =>
                                        handleMenuClick(
                                            `/api/auth/login?returnTo=${asPath}`,
                                        )
                                    }
                                >
                                    {'Login'}
                                </Button>
                                <Button
                                    variant="outlined"
                                    width="100%"
                                    style={{
                                        border: `1px solid red`,
                                        borderRadius: '5px',
                                    }}
                                    onClick={() =>
                                        handleMenuClick(
                                            `/api/auth/signup?returnTo=${asPath}`,
                                        )
                                    }
                                >
                                    {'Signup'}
                                </Button>
                            </div>
                        )}
                        <form
                            action={`/api/stripe/checkout_session?returnURL=${asPath}`}
                            method="POST"
                            target="_self"
                            style={{ width: '100%' }}
                        >
                            <input
                                type="hidden"
                                name="price_id"
                                value={priceId}
                            />
                            {finalPassMode && (
                                <input
                                    type="hidden"
                                    name="price_id_2"
                                    value={final_pass_price_id}
                                />
                            )}
                            <input
                                type="hidden"
                                name="pass_id"
                                value={passId}
                            />
                            <input
                                type="hidden"
                                name="pass_type"
                                value={PASS_TYPE.LEAGUE_PASS}
                            />
                            <SubscribeButton
                                type="submit"
                                role="link"
                                bColor="_primary"
                                width="100%"
                                onClick={trackSubscribe}
                            >
                                {finalPassMode ? 'Purchase' : 'Subscribe'}
                            </SubscribeButton>
                            {
                                <Button
                                    role="link"
                                    width="100%"
                                    onClick={returnToLeague}
                                >
                                    {`Return to League`}
                                </Button>
                            }
                        </form>
                    </SectionWrapper>
                </ModalBody>
            </ModalContent>
        </ModalWrapper>
    )
}

export default LeaguePass
